import "./Getinfo.css";
import infos from "../../../Data/Datapro";
import Card from "../card/Card";
import Rendezvous from "./../Button/Rendezvous";

const Getinfo = () => {
  const serviceinfo = infos.map((e) => {
    return (
      <Card
        key={e.id}
        imgs={e.imgs}
        titre={e.titre}
        info1={e.info1}
        info2={e.info2}
        info3={e.info3}
        info4={e.info4}
      />
    );
  });
  return (
    <>
      <div className="containerS">
        <div className="titles">
          <div className="bg">
            <h1>
              Spécialités chirurgicales
              <br />
              <br />
              <br />
              <Rendezvous />
            </h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <h1 className="text-center mt-5 display-3 fw-bold">
          Spécialités chirurgicales
        </h1>
        <hr className="mx-auto mb-5 w-25" />
        <div className="row row-cols-1 row-cols-md-4 g-4">{serviceinfo}</div>
      </div>
    </>
  );
};

export default Getinfo;
