import "./Hero2.css";
import video from "../../../assets/vidieos.mp4";
import doucteur from "../../../assets/doctor1.JPG";

import Slider from "./../ImageSlider/Slider";

const Hero2 = () => {
  return (
    <>
    <section>
      <div className="heading">
        <h1 className="titlesp">
          Spécialiste en Traumatologie Orthopédie au Maroc
        </h1>
        <hr className="mx-auto mb-5 w-25" />
      </div>
      </section>
      <section className="about1">
        <video className="video"
          src={video}
          autoPlay={false}
          controls={true}
          width="100%"
          height="90%"
          poster={doucteur}
        />
      </section>
      <h1 className="titlesp">
        Les spécialités chirurgicales du Dr Alami Mohammed
      </h1>
      <hr className="mx-auto mb-5 w-25" />
      <Slider />
    </>
  );
};

export default Hero2;
