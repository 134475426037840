import "./Rendezvous.css";
import { FaCalendarWeek } from "react-icons/fa";
import { Link } from "react-router-dom";
const Rendezvous = () => {
  return (
    <div>
      <Link className="link-rendes" to="/Contact">
        Prendre RDV{" "}
        <FaCalendarWeek style={{ paddingBottom: "5px", fontSize: "20PX" }} />
      </Link>
    </div>
  );
};

export default Rendezvous;
