import ser from '../assets/ser.jpg'
import ser1 from '../assets/ser1.jpg'
import ser2 from '../assets/ser2.jpg'
import ser3 from '../assets/ser3.jpg'



const infos = [
  {
    id: "1",
    imgs: ser2,
    titre: "Chirurgie Prothétique",
    info1: "Hanche",
    info2: "Genou",
    info3: "Épaule",

    
  },
  {
    id: "2",
    imgs: ser1,
    titre: "Arthroscopie",
    info1: "Ligamentoplastie du genou.",
    info2: "Réparation meniscale.",
    info3: "Libération endoscopique du nerf médian dans le syndrome du canal carpien",
    // info4: "Suture méniscale",
  },
  {
    id: "3",
    imgs: ser,
    titre: "Chirurgie de la cheville et du pied",
    info1: "Suture et peignage du tendon d'Achille",
    info2: "Arthrodèse de la cheville",
    info3: "Traitement de l'hallux valgus et l'hallux rigidus",
    info4: "Cure de nevrome de Morton",
  },
  {
    id: "4",
    imgs: ser3,
    titre: "Chirurgie traumatologique",
    info1: "Prise en charge des fractures du membre supérieur",
    info2: "Prise en charge des fractures du membre inférieur",
    info3: "Chirurgie des plaies tendineuses et nerveuses des membres ",
    info4: "Traumatologie du sport",
  },
];

export default infos;
