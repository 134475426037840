import "./Footer.css";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo3.png";

const Footer = () => {
  var date=new Date()
  return (
    <>
      <footer className="footer-distributed">
        <div className="footer-left">
          <Link to="/">
            <img src={logo} />
          </Link>
          <p className="footer-links">
            <Link to="/" >
              Accueil 
            </Link>
            <br/>
            <Link to="/About" >
              {" "}
              À propos 
            </Link>
            <br/>
            <Link to="/Service" >
              {" "}
              Spécialités chirurgicales 
            </Link>
            <br/>
            <Link to="/Contact" >
             
              Contact-Rdv{" "}
            </Link>
          </p>

          <p className="footer-company-name">
            Copyright © 2023 <strong>Dr.Alamai Mohammed</strong> 
          </p>
        </div>

        <div className="footer-center">
          <p style={{paddingLeft:"15px", fontSize:"20px",fontWeight:"700",textDecoration:"underline",marginBottom:"24px"}}>Contact</p>
          <div>
            <i>
              <FaMapMarkerAlt style={{ fontSize: "20px" }} />
            </i>
            <p>Rue Ghazza,Bureau 16, angle Av.Hassan II,Témara.</p>
          </div>

          <div>
            <i>
              <FaPhoneAlt style={{ fontSize: "20px" }} />
            </i>
            <p>0537585666</p>
          </div>
          <div>
            <i>
              <FaEnvelope style={{ fontSize: "20px" }} />
            </i>
            <p>
             Dr.Alami@gmail.com
            </p>
          </div>
        </div>
        <div className="footer-right">
          <p className="footer-company-about">
            <span className="text-decoration-underline">À propos du médecin</span>
            <br/>
            <strong>Dr Alami Mohammed</strong> un chirurgien spécialisé en
            chirurgie orthopédique et traumatologique. Il exerce la chirurgie de
            la hanche, du genou, de la cheville et du pied à Maroc.
          </p>
          <div className="footer-icons">
            <a >
              <FaFacebookF />
            </a>
            <a href="#">
              <FaLinkedinIn />
            </a>
            <a href="#">
              <FaTwitter />
            </a>
            <a href="#">
              <FaInstagram />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
