import ScrollToTop from "react-scroll-to-top";
import "./Scroll.css";

const Scroll = () => {
  return (
    <div>
      <ScrollToTop style={{backgroundcolor: "#1d71b8"}} className="totop" smooth />
    </div>
  );
};

export default Scroll;
