import Hero from '../components/section/Hero/Hero'
import Hero2 from '../components/section/Hero2/Hero2';


const Home = () => {
  return (
    <>
      <Hero/>
      <Hero2/>
    </>
  )
}

export default Home
