import "./Slider.css";
import e from "../../../assets/ser.jpg";
import b from "../../../assets/ser1.jpg";
import d from "../../../assets/ser2.jpg";
import c from "../../../assets/ser3.jpg";
// import d from '../../../assets/d.jpg'
const Slider = () => {
  return (
    <>
      <div
        id="carouselExampleCaptions"
        className="carousel slide mb-5"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
           <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              style={{ height: "350px" }}
              src={d}
              className="d-block m-auto"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Chirurgie Prothétique</h5>
              {/* <p>Some representative placeholder content for the first slide.</p> */}
            </div>
          </div>
          <div className="carousel-item">
            <img
              style={{ height: "350px" }}
              src={e}
              className="d-block m-auto"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Chirurgie de la cheville et du pied</h5>
              {/* <p>Some representative placeholder content for the second slide.</p> */}
            </div>
          </div>
          <div className="carousel-item">
            <img
              style={{ height: "350px" }}
              src={c}
              className="d-block m-auto"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Chirurgie traumatologique</h5>
              {/* <p>Some representative placeholder content for the third slide.</p> */}
            </div>
          </div>
          <div className="carousel-item">
            <img
              style={{ height: "350px" }}
              src={b}
              className="d-block m-auto"
              alt="..."
            />
            <div className="carousel-caption d-none d-md-block">
              <h5>Arthroscopie</h5>
              {/* <p>Some representative placeholder content for the first slide.</p> */}
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default Slider;
