import logo from "../../../assets/logo3.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import $ from 'jquery'
const Header = () => {
  useEffect(()=>{
    $('.js-scroll-trigger').click(function () {
       $('.navbar-collapse').collapse('hide');
    });
  })
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light" >
        <div className="container-fluid">
          <Link to="/" className="navbar-brand" >
            <img className="logo"  src={logo} />
          </Link>
          <button
            className=" navbar-toggler "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link active js-scroll-trigger"
                  aria-current="page"
                  href="#"
                >
                  Accueil
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/About" className="nav-link js-scroll-trigger" >
                  À propos
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Service" className="nav-link js-scroll-trigger" href="#">
                  Spécialités chirurgicales
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Contact" className="nav-link js-scroll-trigger" >
                  Contact-Rdv
                </Link>
              </li>
            </ul>
            <button className="btn btn-outline-success" type="submit">
              <Link to="/Contact" className="liens js-scroll-trigger">
                Prendre RDV
              </Link>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
