import { BrowserRouter as Router,Route, Routes} from 'react-router-dom';
import Header from './components/section/Header/Header'
import './App.css';
import Container from './components/container/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/section/Header/Header.css'
import About from './components/section/about/About';
import Footer from './components/section/Footer/Footer';
import Home from './pages/Home';
import Contactus from './pages/Contact/Contactus';
import './App.css'
import Getinfo from './components/section/Services/Getinfo';
import Scroll from './components/section/scroll/Scroll';
import './reload.js'

function App() {
  return (
    <div className="">
      
      <Router basename='/dr-alami'>
      <Header/>
      <Container>
        <Routes>
          <Route  path='/'  element={<Home/>}/>
          <Route path='/About' exact element={<About/>}/>
          <Route path='/Service' exact element={<Getinfo/>}/>
          <Route path='/Contact' exact element={<Contactus/>}/>
        </Routes>
      </Container>
      <Footer/>
      <Scroll/>
      </Router>
    </div>
  );
}

export default App;
