import { FaArrowRight, FaPenSquare, FaBusinessTime } from "react-icons/fa";
import doctor from "../../../assets/abouts.jpeg";
import "./About.css";
import Rendezvous from "./../Button/Rendezvous";

const About = () => {
  return (
    <>
      <div className="containerS">
        <div className="titles">
          <div className="bg">
            <h1>
            Chirurgien spécialiste en Traumatologie-Orthopédie au Maroc
              <br />
              <br />
              <br />
              <Rendezvous />
            </h1>
          </div>
        </div>
      </div>
      <h1 className="text-center mt-5 display-3 fw-bold">À propos</h1>
      <hr className="mx-auto mb-5 w-25" />
      <div className="about-sec">
        <div className="about-img">
          <img src={doctor} />
        </div>
        <div className="about-intro">
          <h3 style={{ color: "#1d71b8" }}> Dr Alami Mohammed</h3>
          <p>
            Le docteur Alami Mohammed est diplomé de la chirurgie orthopédique
            et traumatologique. Apres un internat et résidanat au CHU Ibn Sina
            de rabat , il a réalisé un stage de perfectionnement aux hôpitaux de
            France. Il a travaillé aux hôpitaux publiques après en tant que
            chirurgien orthopédiste et traumatologue pendant douze années
            période pendant laquelle il a acquis une bonne expérience. Installé
            au secteur libéral depuis janvier 2022. Ce cumul d'expérience lui
            permet de vous garantir la meilleure prise en charge selon les
            règles de l'art. Le docteur Alami Mohammed est inscrit à l'ordre
            national des médecins sous le numéro 7949.
          </p>
        </div>
      </div>
      <h1>
        Heure D'ouverture <FaBusinessTime />
      </h1>
      <hr className="mx-auto mb-5 w-25" />
      <div className="about-section">
        <div className="inner-container bg-light">
          <h1>Cabinet de Traumatologie Orthopédie</h1>
          <ul>
            <li>Lundi-Vendredi</li>
            <span>
              9:00AM <FaArrowRight /> 18:00PM
            </span>
            <li>Samedi</li>
            <span>
              9:00AM <FaArrowRight /> 13:00PM
            </span>
            <li>Dimanche</li>
            <span style={{ color: "red" }}>Fermé</span>
          </ul>
        </div>
      </div>
      <h1>
        Curriculum vitae <FaPenSquare />
      </h1>
      <hr className="mx-auto mb-5 w-25" />
      <div className="content2">
        <ul>
          <li>Diplôme de médecine générale .</li>
          <li>
            Diplôme de spécialité en Traumatologie-Orthopédie de la faculté de
            médecine de Rabat .
          </li>
          <li>Ancien interne du CHU de Rabat et des hôpitaux de France .</li>

          <li>Diplôme de chirurgie du pied .</li>
          <li>Diplôme d'échographie en appareil locomoteur .</li>
          <li>Diplôme d'arthroscopie chirurgical </li>
          <li>Diplôme de pathologie du rachis de Bordeaux</li>
        </ul>
      </div>
    </>
  );
};

export default About;
