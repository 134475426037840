import "./Hero.css";
import Rendezvous from "../Button/Rendezvous";
const Hero = () => {
  return (
    <div className="hero-header">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center text-lg-start">
            <h2
              data-text="Cabinet de Traumatologie Orthopédie"
              className="texts-bg"
            >
              Cabinet de Traumatologie Orthopédie <span>Dr</span>
              <span> Alami</span>
              <span> Mohammed</span>
            </h2>
            <br />
            <br />
            <div className="d-flex justify-content-center pt-2">
              <Rendezvous />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
