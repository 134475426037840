import "./Card.css";
import infos from "./../../../Data/Datapro";
const Card = (props) => {
  return (
    <>
      
      <div className="col">
        <div className="card h-100">
          <img src={props.imgs} className="card-img-top" />
          <div className="card-body">
            <h5 className="card-title text-center" style={{fontWeight:"bold",fontSize:"17px"}}>{props.titre}</h5>
            <hr className="mx-auto w-75" />
            <ul>
              {props.info1 &&<li>{props.info1}</li>}
              {props.info2 &&<li>{props.info2}</li>}
              {props.info3 &&<li>{props.info3}</li>}
              {props.info4 &&<li>{props.info4}</li>}
              
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
