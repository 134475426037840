import { useRef, useState } from "react";
import emailjs from "emailjs-com";
import "./Contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  //function to check nom:
  const [errorn, setErrorN] = useState({ etat: true, messagen: "" });
  const validNom = (e) => {
    const nom = e.target.value;
    if (nom=="") {
      setErrorN({ etat: true, messagen: "Saises votre nom" });
    } else {
      setErrorN({ etat: false, messagen: "" });
    }
  };
  //function to check e-mails:
  const [errore, setErrore] = useState({ etat: true, messageE: "" });
  const validEmail = (e) => {
    const email = e.target.value;
    if (email == "") {
      setErrore({ etat: true, messageE: "Saises votre email" });
    } else if (
      email !=
      email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      setErrore({ etat: true, messageE: "Adresse e-mail invalide!" });
    } else {
      setErrore({ etat: false, messageE: "" });
    }
  };

  //function to check phone:
  const [errort, setErrort] = useState({ etat: true, messageT: "" });
  const validTel = (e) => {
    const phone = e.target.value;
    if (phone == "") {
      setErrort({ etat: true, messageT: "Saises le numéro de téléphone" });
    } else {
      setErrort({ etat: false, messageT: "" });
    }
  };
  //function to check the appointment date:
  const [errord, setErrord] = useState({ etat: true, messageD: "" });
  const validDate = (e) => {
    const dateReserve = e.target.value;
    //    const today=new Date();
    if (dateReserve == "") {
      setErrord({ etat: true, messageD: "cette date n'est pas disponible" });
    }
    else{
        setErrord(({etat:false,messageD:""}))
    }
  };

  // function for send message to email:
  const from = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    if (errorn.etat || errore.etat || errort.etat || errord.etat) {
    console.log('cannot send');
    } else {
      emailjs
        .sendForm(
          "service_pcy2vtv",
          "template_2gazt0k",
          from.current,
          "7pCHU5G1GMFOiJUWZ"
        )
        .then(() =>
          toast.success("Votre rendez-vous a été fixé", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          })
        );
    }
  };
  //fonction pour vider les champs:


  return (
    <>
      <div className="containerS">
        <div className="titles">
          <div className="bg">
            <h1>Prendre RDV avec un chirurgien orthopédiste au Maroc</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <h1 className="text-center mt-5 display-3 fw-bold">Contact-Rdv</h1>
        <hr className="mx-auto mb-5 w-25" />
        <form ref={from} onSubmit={sendEmail}>
          <div className="row co">
            <div className="column">
              <label>
                Nom <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </label>
              <input
                type="text"
                onChange={validNom}
                name="to_name"
                id="name"
                placeholder="nom complet"
                required
              />
              {<p className="error">{errorn.messagen}</p>}
            </div>

            <div className="column">
              <label>
                Email <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </label>
              <input
                type="email"
                onChange={validEmail}
                name="from_name"
                id="email"
                placeholder="email"
                required
              />
              {<p className="error">{errore.messageE}</p>}
            </div>
          </div>
          <div className="row co">
            <div className="column">
              <label>
                Date rendez-vous
                <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </label>
              <input
                type="datetime-local"
                onChange={validDate}
                name="from_date"
                id="subject"
                required
              />
              {<p className="error">{errord.messageD}</p>}
            </div>
            <div className="column">
              <label>
                Téléphone{" "}
                <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </label>
              <input
                type="tel"
                onChange={validTel}
                name="tel"
                id="contact"
                placeholder="N° téléphone"
                required
              />
              {<p className="error">{errort.messageT}</p>}
            </div>
          </div>
          <div className="row co">
            <div className="column ">
              <label>Décrivez votre problème</label>
              <textarea
                id="issue"
                placeholder="Message"
                name="message"
                rows="3"
              ></textarea>
            </div>
          </div>
          <button className="bnt" type="submit">
            Envoyer
          </button>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </form>
      </div>
      <br />
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            style={{
              width: "100%",
              height: "230px",
              frameborder: "0",
              scrolling: "no",
              marginheight: "0",
              marginwidth: "0",
            }}
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Cabinet de Traumatologie Orthopédie Dr Alami Mohammed&t=&z=11&ie=UTF8&iwloc=&output=embed"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Contact;
